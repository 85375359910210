<template>
  <div class="panel-detail panel-fixed panel-with-actions">
    <div class="panel-header">
      <h1 class="page-header">
        {{ $t('Yard') }}&nbsp; <span v-if="detail">{{detail.name ? detail.name: detail.customer_name}} ({{detail.code}}) </span>
        <div v-if="$route.params.id" class="page-header-actions">
          <b-btn
            variant="outline-primary"
            v-has-perms="yardAttachmentListRolePerm"
            :title="'Show attachments' | translate"
            :to="{ name: YARD_ROUTES.YARD_ATTACHMENT_LIST, params: { id: $route.params.id }}"
          >
            <i class="fas fa-paperclip mr-1"></i>
            <span class="font-weight-bold" style="font-size: .8rem">
              {{ detail.attachment_count ? detail.attachment_count : 0 }}
            </span>
          </b-btn>
          <b-btn
            variant="outline-primary"
            v-has-perms="showInspectionsRolePerm"
            :title="'Show inspections filtered by yard' | translate"
            :to="{ name: YARD_ROUTES.YARD_INSPECTIONS_LIST, params: { id: $route.params.id }}"
          >
            <i class="fas fa-wrench"></i>
          </b-btn>
          <b-btn
            variant="outline-primary"
            v-has-perms="showContractorsRolePerm"
            :title="'Show contractors filtered by yard' | translate"
            :to="{ name: YARD_ROUTES.YARD_CONTRACTOR_LIST, params: { id: $route.params.id }}"
          >
            <i class="far fa-building"></i>
          </b-btn>
          <b-btn
            variant="outline-primary"
            v-has-perms="showTechniciansRolePerm"
            :title="'Show technicians filtered by yard' | translate"
            :to="{ name: YARD_ROUTES.YARD_TECHNICIANS_LIST, params: { id: $route.params.id }}"
          >
            <i class="far fa-user"></i>
          </b-btn>
          <b-btn
            variant="outline-primary"
            v-has-perms="showControlBodiesRolePerm"
            :title="'Show control bodies filtered by yard' | translate"
            :to="{ name: YARD_ROUTES.YARD_CONTROL_BODIES_LIST, params: { id: $route.params.id }}"
          >
            <i class="fas fa-user-plus"></i>
          </b-btn>
          <b-btn
            variant="outline-primary"
            v-has-perms="showDocumentsRolePerm"
            :title="'Show documents filtered by yard' | translate"
            :to="{
              name: YARD_ROUTES.YARD_DOCUMENTS_LIST,
              params: { yardId: $route.params.id }
            }"
          >
            <i class="far fa-file-alt"></i>
          </b-btn>
            <b-btn
              variant="outline-primary"
              v-has-perms="generateYardDocumentsRolePerm"
              :title="'Generate documents requests' | translate"
              @click.prevent="generateYardDocuments($route.params.id)"
            >
              <i class="fas fa-folder-plus"></i>
            </b-btn>
        </div>
      </h1>
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard'}">
            {{"Dashboard"}}
          </router-link>
        </li>
        <li class="breadcrumb-item">
          <span @click="goToList" class="underline-on-hover">
            {{ 'Yards' | translate }}
          </span>
        </li>
        <li class="breadcrumb-item">
          <span>
            {{ 'Yard' | translate }}
          </span>
        </li>
      </ol>
    </div>
    <yard-edit :id="$route.params.id"/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as YARD_TYPES } from './store'
import { ROUTES as YARD_ROUTES } from './router'
import YardEdit from './YardEdit'

export default {
  name: 'YardEditPage',
  components: {
    'yard-edit': YardEdit
  },
  data () {
    return {
      pageName: 'Yard',
      yardAttachmentListRolePerm: 'yard_yardattachment_list',
      showInspectionsRolePerm: 'yard_yardinspection_list',
      showContractorsRolePerm: 'yard_yardcontractor_list',
      showTechniciansRolePerm: 'yard_yardtechnician_list',
      showControlBodiesRolePerm: 'yard_yardcontrolbody_list',
      showDocumentsRolePerm: 'document_uploaddocument_list',
      generateYardDocumentsRolePerm: 'yard_yard_generate_documents',
      form: {},
      YARD_ROUTES
    }
  },
  created () {
    this.setDetail({})
    if (this.$route.params.id) {
      this.retrieve({ id: this.$route.params.id })
    }
  },
  computed: {
    ...mapGetters({
      detail: YARD_TYPES.GENERIC.yard.yard.DETAIL.GETTERS.detail
    })
  },
  methods: {
    ...mapMutations({
      setDetail: YARD_TYPES.GENERIC.yard.yard.DETAIL.MUTATIONS.setDetail
    }),
    ...mapActions({
      retrieve: YARD_TYPES.GENERIC.yard.yard.DETAIL.ACTIONS.retrieve,
      generateYardDocuments: YARD_TYPES.GENERIC.yard.yard.DETAIL.ACTIONS.generateYardDocuments
    }),
    goToList () {
      this.$router.push({ name: YARD_ROUTES.YARD_LIST })
    }
  }
}
</script>
